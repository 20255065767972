import React, { useState } from "react";
import { postData } from "../FatchApi/FatchApi";
import PageWrapper from "../components/PageWrapper";
import { useNavigate } from "react-router-dom";

const UpdateImage = () => {
  const navigate = useNavigate();

  const [productData, setProductData] = useState({
    heroBanner: "",
    featuredBanner: "",
    homeSingleBanner: "",
    newProductBanner: "",
    productBanner: "",
    comboProductBanner: "",
    blogsBanner: "",
    recipeBanner: "",
  });

  const handleSingleFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;

    setProductData((prevData) => ({
      ...prevData,
      [name]: file,
    }));
  };

  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    const name = e.target.name;
    const fileURLs = fileArray.map((file) => file[0]);
    setProductData((prevData) => ({
      ...prevData,
      [name]: fileArray,
      imageUrls: fileURLs,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contanttype = "";

    console.log(productData);

    const formData = new FormData();
    if (productData.heroBanner.length !== 0) {
      productData.heroBanner.forEach((image, index) => {
        formData.append(`heroBanner`, image);
      });
    }
    if (productData.featuredBanner.length !== 0) {
      productData.featuredBanner.forEach((image, index) => {
        formData.append(`featuredBanner`, image);
      });
    }
    if (productData.homeSingleBanner !== "") {
      formData.append("homeSingleBanner", productData.homeSingleBanner);
    }
    if (productData.newProductBanner !== "") {
      formData.append("newProductBanner", productData.newProductBanner);
    }
    if (productData.productBanner !== "") {
      formData.append("productBanner", productData.productBanner);
    }
    if (productData.comboProductBanner !== "") {
      formData.append("comboProductBanner", productData.comboProductBanner);
    }
    if (productData.blogsBanner !== "") {
      formData.append("blogsBanner", productData.blogsBanner);
    }
    if (productData.recipeBanner !== "") {
      formData.append("recipeBanner", productData.recipeBanner);
    }

    const res = await postData("api/images/images-upload", {
      data: formData,
      contanttype,
    });
    console.log(res);
    if (res.success) {
      navigate("/manage-images");
    } else {
      alert(res.message);
    }
  };

  return (
    <>
      <PageWrapper>
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 mx-auto">
            <div className="mb-4">
              <label
                htmlFor="heroBanner"
                className="block mb-1 font-medium text-white"
              >
                Hero Banner (Please selelet all images)
              </label>

              <input
                type="file"
                id="heroBanner"
                name="heroBanner"
                onChange={handleMultiFileChange}
                multiple
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="featuredBanner"
                className="block mb-1 font-medium text-white"
              >
                Featured Product Banner (Please selelet all images)
              </label>

              <input
                type="file"
                id="featuredBanner"
                name="featuredBanner"
                onChange={handleMultiFileChange}
                multiple
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="homeSingleBanner"
                className="block mb-1 font-medium text-white"
              >
                Home Banner (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="homeSingleBanner"
                name="homeSingleBanner"
                onChange={handleSingleFileChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="newProductBanner"
                className="block mb-1 font-medium text-white"
              >
                New Launch Product Banner (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="newProductBanner"
                name="newProductBanner"
                onChange={handleSingleFileChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="productBanner"
                className="block mb-1 font-medium text-white"
              >
                Product Banner (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="productBanner"
                name="productBanner"
                onChange={handleSingleFileChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="comboProductBanner"
                className="block mb-1 font-medium text-white"
              >
                Combo Product Banner (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="comboProductBanner"
                name="comboProductBanner"
                onChange={handleSingleFileChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="blogsBanner"
                className="block mb-1 font-medium text-white"
              >
                Blog Banner (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="blogsBanner"
                name="blogsBanner"
                onChange={handleSingleFileChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="recipeBanner"
                className="block mb-1 font-medium text-white"
              >
                Recipe Banner (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="recipeBanner"
                name="recipeBanner"
                onChange={handleSingleFileChange}
              />
            </div>
          </div>
          <div className="mb-4 bg-white">
            <button
              className="px-4 py-2 mt-5  text-white bg-blue-500 rounded hover:bg-blue-600 float-right text-center"
              type="submit"
            >
              Upload Blog
            </button>
          </div>
        </form>
      </PageWrapper>
    </>
  );
};

export default UpdateImage;
