import React, { Suspense, useEffect, useState } from "react";
import LoadingIndicator from "../components/LoadingIndicator";
import ContentBox from "../components/contents/ContentBox";
import DisplaySection from "../components/DisplaySection";
import PageWrapper from "../components/PageWrapper";
import SortByTag from "../components/SortByTag";
import Search from "../components/Search";
import LeadershipIcon from "../assets/images/leadership.svg";
import ShoppingCartIcon from "../assets/images/shopping-cart.svg";
import TotalSalesIcon from "../assets/images/sales.svg";
import StoreIcon from "../assets/images/store.svg";
import configs from "../configs.json";
import { BaseURL, getData, postData } from "../FatchApi/FatchApi";
import { useNavigate } from "react-router-dom";

const OrderedProductPreview = React.lazy(() =>
  import("../components/products/OrderedProductPreview")
);

const DashboardPage = () => {
  const navigate = useNavigate();
  const [allOrders, setAllOrders] = useState([]);
  const [discountPercentage, setdiscountPercentage] = useState(0);
  const [aboveValue, setAboveValue] = useState();
  const [freeDeliveryAbove, setFreeDeliveryAbove] = useState(1000);
  const [getdiscount, setGetdiscount] = useState();

  const getAllOrders = async () => {
    const res = await getData("api/orders/get-all-order");
    if (res == "Token is not valid!") {
      localStorage.clear();
      navigate("/");
    }
    setAllOrders(res.AllOrders);
  };

  const getDiscount = async () => {
    const res = await getData("api/discount");
    setGetdiscount(res[0])
  }

  useEffect(() => {
    getAllOrders();
    getDiscount()
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { discountPercentage, aboveValue, freeDeliveryAbove };
    const Token = localStorage.getItem("token");
    const response = await fetch(`${BaseURL}/api/discount/add`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      alert("Discount Add Successfuly")
      getDiscount()
    }
  };

  return (
    <PageWrapper>
      <div className="w-full">
        <div className="flex justify-between relative">
          <div>
            <h1 className="text-lg text-gray-100 font-bold leading-5">
              Hello {configs.userName}!
            </h1>
            <p className="text-gray-300 leading-5">
              Welcome back {configs.userName.split(" ")[0]}, and great to meet
              you
            </p>
          </div>
          <div className="ml-1">
            <Search />
          </div>
        </div>

        {/* Store status */}
        {/* <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4 overflow-x-auto mt-10">
          <DisplaySection
            displayText="$5333"
            subText="Total Sales"
            icon={TotalSalesIcon}
          />

          <DisplaySection
            icon={ShoppingCartIcon}
            displayText={"133"}
            subText={"Product Orders"}
          />

          <DisplaySection
            icon={StoreIcon}
            displayText={"3344"}
            subText={"Store Views"}
          />

          <DisplaySection
            icon={LeadershipIcon}
            displayText={"234"}
            subText={"Product Views"}
          />
        </div> */}
        <div className="my-5">
          <h2 className="text-[25px] py-5 text-gray-100 text-center font-bold leading-5">
            Discount
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <form onSubmit={handleSubmit} className="bg-gray-100 p-6 rounded-lg shadow-md grid grid-cols-2 gap-2">
              <div className="mb-1">
                <label htmlFor="discountPercentage" className="block text-gray-700 font-semibold mb-2">Discount Percentage</label>
                <input
                  type="number"
                  id="discountPercentage"
                  value={discountPercentage}
                  onChange={(e) => setdiscountPercentage(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>

              <div className="mb-1">
                <label htmlFor="aboveValue" className="block text-gray-700 font-semibold mb-2">Above Value</label>
                <input
                  type="number"
                  id="aboveValue"
                  value={aboveValue}
                  onChange={(e) => setAboveValue(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>

              <div className="mb-1">
                <label htmlFor="freeDeliveryAbove" className="block text-gray-700 font-semibold mb-2">Free Delivery Above</label>
                <input
                  type="number"
                  id="freeDeliveryAbove"
                  value={freeDeliveryAbove}
                  onChange={(e) => setFreeDeliveryAbove(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>

              <button type="submit" >
                <span className="bg-indigo-500  text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition duration-300">Apply Discount</span>
              </button>
            </form>

            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="grid grid-cols-3">
                <h2 className="text-2xl text-gray-700 font-semibold col-span-2 mb-4">Discount Percentage :</h2>
                <h2 className="text-2xl text-gray-700 font-semibold mb-4">{getdiscount?.discountPercentage} %</h2>
              </div>
              <div className="grid grid-cols-3">
                <h2 className="text-2xl text-gray-700 font-semibold col-span-2 mb-4">Above Value  :</h2>
                <h2 className="text-2xl text-gray-700 font-semibold mb-4">₹ {getdiscount?.aboveValue}</h2>
              </div>
              <div className="grid grid-cols-3">
                <h2 className="text-2xl text-gray-700 font-semibold col-span-2 mb-4">Free Delivery Above :</h2>
                <h2 className="text-2xl text-gray-700 font-semibold mb-4">₹ {getdiscount?.freeDeliveryAbove}</h2>
              </div>

            </div>
          </div>
        </div>

        {/* Active orders */}
        <ContentBox name="Orders" showSearch>
          <>
            <div className="border-b border-b-glitch-box">
              <SortByTag tags={[]} />
            </div>

            <div className="mt-5"></div>
            <Suspense fallback={<LoadingIndicator />}>
              <ul className="px-5 bg-transparent">
                {allOrders?.map((Order, i) => (
                  <li
                    className="block mt-4 pb-4 border-b border-b-glitch-box last:border-none"
                    key={i}
                  >
                    <OrderedProductPreview orderId={Order?._id} />
                  </li>
                ))}
              </ul>
            </Suspense>
          </>
        </ContentBox>
      </div>
    </PageWrapper>
  );
};

export default DashboardPage;
