import React, { useState } from "react";
import PageWrapper from "../components/PageWrapper";
import ReactStars from "react-rating-stars-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postData, putData2 } from "../FatchApi/FatchApi";
const ReviewDetailsPage = ({ props }) => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const updateStatus = async () => {
    const body = {
      approvement: value,
    };
    const formData = {
      contanttype: "application/json",
      data: JSON.stringify(body),
    };
    const update = await putData2(
      `api/review/update-approvment-status/${state?.data?._id}`,
      formData
    );
    if (update?.success) {
      alert(update?.message);
      navigate("/manage-reviews");
    }
  };
  return (
    <div>
      <PageWrapper>
        <div className="container shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
          <div className="flex sm:flex-row  p-10">
            <div>
              <img
                src={`https://api.garnifoods.com/uploads/${state?.data?.productId.images[0]}`}
                alt
                className="h-[110px] w-[110px]"
              />
            </div>
            <div className="ml-10">
              <h1 className="text-white text-2xl">
                {state?.data?.productId?.title}
              </h1>
              <p className="mt-2">
                Mrp :{" "}
                <span className="text-white">
                  {state?.data?.productId?.mrp}
                </span>
              </p>
              <p className="mt-2">
                Weight :{" "}
                <span className="text-white">
                  {state?.data?.productId?.weight}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-2 p-10">
            <div className="flex justify-between flex-col sm:flex-row ">
              <div className="flex flex-row pt-5  w-12/12 sm:w-6/12 ">
                <div className="">
                  <img
                    className="h-10 w-10 rounded-full mx-3 "
                    src="https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
                  />
                </div>
                <div className="pt-1">
                  <p>{state?.data?.userId.username}</p>
                  <p>{new Date(state?.data?.createdAt).toLocaleString()}</p>
                  <ReactStars
                    count={"5"}
                    value={state?.data?.ratingStars}
                    size={25}
                    activeColor="#ffd700"
                    edit={false}
                  />
                </div>
              </div>
              <div className="pt-7  flex flex-row w-12/12 sm:w-6/12 justify-center">
                <div className="mr-2 mb-2 flex flex-col">
                  <label className="mb-2">Approvement</label>
                  <select
                    name="selectbox "
                    id="option"
                    className="w-[160px] h-8 "
                    onChange={(e) => setValue(e.target.value)}
                  >
                    <option selected>Select</option>
                    <option
                      selected={state?.data?.approvement == "approved"}
                      value={"approved"}
                    >
                      Approve
                    </option>
                    <option
                      selected={state?.data?.approvement == "rejected"}
                      value={"rejected"}
                    >
                      Reject
                    </option>
                  </select>
                </div>

                <div className=" mt-8">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                    onClick={updateStatus}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="flex p-5">
              <p className="text-white">{state?.data?.reviewComment}</p>
            </div>
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

export default ReviewDetailsPage;
