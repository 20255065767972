import React, { useState } from "react";
import { postData, putData } from "../FatchApi/FatchApi";

const ProductAddForm = ({ id }) => {
  const initialFormData = {
    title: id.title ? id.title : "",
    metatitle: id.metatitle ? id.metatitle : "",
    productName: id.productName ? id.productName : "",
    productDescription: id.productDescription ? id.productDescription : "",
    metaDescription: id.metaDescription ? id.metaDescription : "",
    metaKeywords: id.metaKeywords ? id.metaKeywords : "",
    slug: id.slug ? id.slug : "",
    quantity: id.quantity ? id.quantity : "",
    images: id.images ? id.images : "",
    category: id.category ? id.category : "",
    mrp: id.mrp ? id.mrp : "",
    discount: id.discount ? id.discount : 0,
    sellingPrice: id.sellingPrice ? id.sellingPrice : "",
    hsn: id.hsn ? id.hsn : "",
    tax: id.tax ? id.tax : "",
    sku: id.sku ? id.sku : "",
    ean: id.EanCode ? id.EanCode : "",
    manufacturerDetails: id.manufacturerDetails ? id.manufacturerDetails : "",
    shelfLife: id.shelfLife ? id.shelfLife : "",
    length: id.length ? id.length : "",
    breadth: id.breadth ? id.breadth : "",
    height: id.height ? id.height : "",
    weight: id.weight ? id.weight : "",
    type: id.type ? id.type : "",
    ingredients: id.ingredients ? id.ingredients : "",
    nutrientContent: id.nutrientContent ? id.nutrientContent : "",
  };

  const [productData, setProductData] = useState(initialFormData);
  const [sellingPrice, setSellingPrice] = useState(id?.sellingPrice);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    const fileURLs = fileArray.map((file) => file[0]);
    setProductData((prevData) => ({
      ...prevData,
      images: fileArray,
      imageUrls: fileURLs,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(productData.images);
    const contanttype = "";
    const formData = new FormData();
    formData.append("title", productData.title);
    formData.append("metatitle", productData.metatitle);
    formData.append("productDescription", productData.productDescription);
    formData.append("metaDescription", productData.metaDescription);
    formData.append("metaKeywords", productData.metaKeywords);
    formData.append("slug", productData?.slug);
    formData.append("productName", productData.productName);
    formData.append("quantity", productData.quantity);
    formData.append("category", productData.category);
    formData.append("mrp", productData.mrp);
    formData.append("discount", productData.discount);
    formData.append("sku", productData.sku);
    formData.append("tax", productData.tax);
    formData.append("hsn", productData.hsn);
    formData.append("EanCode", productData.ean);
    formData.append("sellingPrice", sellingPrice);
    formData.append("manufacturerDetails", productData.manufacturerDetails);
    formData.append("shelfLife", productData.shelfLife);
    formData.append("length", productData.length);
    formData.append("breadth", productData.breadth);
    formData.append("height", productData.height);
    formData.append("weight", productData.weight);
    formData.append("type", productData.type);
    formData.append("ingredients", productData.ingredients);
    formData.append("nutrientContent", productData.nutrientContent);

    if (productData.images !== 0 || productData.images !== " ") {
      productData.images?.forEach((image, index) => {
        formData.append(`images`, image);
      });
    }

    if (id) {
      const Id = id._id;
      const res = await putData(`api/products/${Id}`, {
        data: formData,
        contanttype,
      });

      if (res && res.status === "success") {
        // setProductData(initialFormData1);
        // alert(res?.message);
        window.location.reload();
      } else {
        alert(res?.message);
      }
    } else {
      const res = await postData("api/products", {
        data: formData,
        contanttype,
      });
      if (res && res.status === "success") {
        // setProductData(initialFormData1);
        // alert(res.message);
        window.location.reload();
      } else {
        alert(res.message);
      }
    }
  };

  return (
    <form
      encType="multipart/form-data"
      onSubmit={handleSubmit}
      className="grid grid-cols-2 gap-4 mx-auto"
    >
      <div className="mb-4">
        <label htmlFor="category" className="block mb-1 font-medium">
          Category
        </label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="category"
          name="category"
          value={productData.category}
          onChange={handleChange}
          required
        >
          <option value="All">Select category</option>
          <option value="BlendedSpices">Blended Spices</option>
          <option value="StraightSpices">Straight Spices</option>
          <option value="WholeSpices">Whole Spices</option>
          <option value="Pulses">Pulses</option>
          <option value="HingAsafoetida">Hing/Asafoetida</option>
          <option value="ComboOffers">Combo Offers</option>
          <option value="PujaItems">Puja Items</option>
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="name" className="block mb-1 font-medium">
          Product Name
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="productName"
          name="productName"
          value={productData.productName}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="title" className="block mb-1 font-medium">
          Title
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="title"
          name="title"
          value={productData.title}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="title" className="block mb-1 font-medium">
          Meta Title
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="metatitle"
          name="metatitle"
          value={productData.metatitle}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="productDescription" className="block mb-1 font-medium">
          Product Description
        </label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded bg-light"
          rows="3"
          placeholder="Enter product description"
          id="productDescription"
          name="productDescription"
          value={productData.productDescription}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <div className="mb-4">
        <label htmlFor="productDescription" className="block mb-1 font-medium">
          Meta Description
        </label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded bg-light"
          rows="3"
          placeholder="Enter meta description"
          id="metaDescription"
          name="metaDescription"
          value={productData.metaDescription}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="mb-4">
        <label htmlFor="metaKeywords" className="block mb-1 font-medium">
          Meta Keywords
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="metaKeywords"
          name="metaKeywords"
          value={productData.metaKeywords}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="metaKeywords" className="block mb-1 font-medium">
          Product URL
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="metaKeywords"
          name="slug"
          disabled={id.slug ? true : false}
          value={productData.slug}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="quantity" className="block mb-1 font-medium">
          Quantity
        </label>
        <input
          type="number"
          step="1"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Enter quantity"
          id="quantity"
          name="quantity"
          value={productData.quantity >= 0 ? productData.quantity : ""}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="mrp" className="block mb-1 font-medium">
          MRP
        </label>
        <input
          type="number"
          step="1"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Enter MRP Rs"
          id="mrp"
          name="mrp"
          value={productData.mrp >= 0 ? productData.mrp : ""}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="mrp" className="block mb-1 font-medium">
          Discount %
        </label>
        <input
          type="number"
          step="1"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="0"
          id="discount"
          name="discount"
          value={productData.discount}
          onChange={(e) => {
            handleChange(e);
            setSellingPrice(
              Math.floor(
                productData.mrp - (productData?.mrp * e.target.value) / 100
              )
            );
          }}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="sellingPrice" className="block mb-1 font-medium">
          Selling Price
        </label>
        <input
          type="number"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Enter selling price Rs"
          id="sellingPrice"
          name="sellingPrice"
          value={sellingPrice}
          onChange={(e) => setSellingPrice(e.target.value)}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="sku" className="block mb-1 font-medium">
          SKU
        </label>
        <input
          type="text"
          step="1"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Enter SKU "
          id="sku"
          name="sku"
          value={productData.sku}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="tax" className="block mb-1 font-medium">
          TAX
        </label>
        <select
          step="1"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Enter Tax"
          id="tax"
          name="tax"
          value={productData.tax}
          onChange={handleChange}
          required
        >
          <option selected> Select One</option>
          <option value={0}>GST_0</option>
          <option value={12}>GST_12</option>
          <option value={18}>GST_18</option>
          <option value={28}>GST_28</option>
          <option value={3}>GST_3</option>
          <option value={5}>GST_5</option>
          <option value={"GST_APPAREL"}>GST_APPAREL</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="mrp" className="block mb-1 font-medium">
          HSN
        </label>
        <input
          type="number"
          step="1"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Enter HSN"
          id="hsn"
          name="hsn"
          value={productData.hsn}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="mrp" className="block mb-1 font-medium">
          EAN Code
        </label>
        <input
          type="number"
          step="1"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Enter Ean "
          id="ean"
          name="ean"
          value={productData.ean}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="manufacturerDetails" className="block mb-1 font-medium">
          Manufacturer Details
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="manufacturerDetails"
          name="manufacturerDetails"
          value={productData.manufacturerDetails}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="shelfLife" className="block mb-1 font-medium">
          Shelf Life
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="shelfLife"
          name="shelfLife"
          value={productData.shelfLife}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="weight" className="block mb-1 font-medium">
          Weight
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="weight"
          name="weight"
          value={productData.weight}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="type" className="block mb-1 font-medium">
          Type
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="type"
          name="type"
          value={productData.type}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="ingredients" className="block mb-1 font-medium">
          Ingredients
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="ingredients"
          name="ingredients"
          value={productData.ingredients}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="nutrientContent" className="block mb-1 font-medium">
          Nutrient Content
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded"
          id="nutrientContent"
          name="nutrientContent"
          value={productData.nutrientContent}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="image" className="block mb-1 font-medium">
          Image Upload
        </label>
        <input
          type="file"
          id="images"
          name="images"
          onChange={handleFileChange}
          multiple
        />
      </div>

      <div className="text-center">
        <button className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
          Save Product
        </button>
      </div>
    </form>
  );
};

export default ProductAddForm;
